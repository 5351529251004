.financer-payment-top-ribben {
  .loan-from {
    fieldset {
      &.search-bx {
        width: 31%;
        i {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0%, -50%);
          font-size: 23px;
          color: #000;
          font-weight: normal;
        }
      }
    }
    .datefrom {
      width: 13%;
    }
    .react-date-picker__inputGroup {
      min-width: 79% !important;
      font-size: 12px;
    }
    .react-date-picker {
      display: inline-flex;
      position: relative;
      width: 100%;
    }
    .bnak-detail {
      width: 14%;
    }
    .executive-dropdown {
      width: 19%;
      min-width: auto;
      max-width: initial;
    }
    .citydrop-down {
      width: 13.4%;
    }
  }
}
.selectd-chip-add-row-btn {
  padding: 15px 0;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .selected-chips {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        margin-bottom: 8px;
        background: $white;
        padding: 6px 6px 6px 17px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        display: flex;
        align-items: center;
        border-radius: 16px;
        margin-right: 8px;
        span {
          background: #e3e7f1;
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 9px;
          border-radius: 12px;
        }
      }
    }
  }
  .add-row-btn {
    .btn-line {
      min-width: auto;
      padding: 0px 25px;
      border-radius: 8px;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}

.loan-from {
  .css-26l3qy-menu {
    width: auto;
    .css-yt9ioa-option {
      white-space: nowrap;
      font-size: 12px;
    }
    .css-1n7v3ny-option {
      white-space: nowrap;
      font-size: 12px;
    }
    .css-9gakcf-option {
      white-space: nowrap;
      background-color: #0bbddd;
      font-size: 12px;
    }
  }
  .css-1vr111p-option,
  .css-1qprcsu-option {
    font-size: 12px;
    font-weight: normal;
    color: rgba($heading-color, 0.6);
  }

  .multiselect-dropDown input {
    position: relative;
    top: 3px;
  }
}
