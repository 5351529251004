.salary-report-filter-outer{
    display: flex;
    align-items: center;
    .searchbox{
        width: 20%;
        .ic-search{
            position: absolute;
            left: 15px;
            font-size: 22px;
            top: 50%;
            transform: translateY(-50%);
            
        }
        .material{
            .form-input{
                padding-left: 50px;
                height: 42px;
                margin-bottom: 0px;
                &:focus,
                &:visited,
                &:focus-within,
                &:focus-visible {
                  ~ label {
                    &:before {
                      top: -8px;
                      font-size: 12px;
                    }
                  }
                }
                &:not(:placeholder-shown) {
                  ~ label {
                    &:before {
                      top: -8px;
                      font-size: 12px;
                    }
                  }
                }

            }
         .form-label{
            &::before{
                left: 50px;
                top: 11px;
            }
        }
    }
    }
    fieldset{
        width: 14%;
        margin-right: 10px;
    }
    .salary-period-picker{
        width: 17%;
    }
}

.salary-filter-main{
    background: $white;
    padding: 20px 30px;
    display: block;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;

    .sub-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: heading-color;
        display: block;
        // padding-bottom: 15px;
    }

    .filter-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 15px;
    }   
    .btn-submit-reset {
        display: flex;
        align-items: center;

        .btn-primary {
            min-width: 180px;
        }

        .btn-reset {
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border: 0px;
            color: $heading-color;
            cursor: pointer;
            margin-left: 10px;
        }
    }
}

.salary-data-report-table-outer{
    background: #fff;
    border-radius: 6px;
    .table-scrollable-data{
        width: 100%;
    overflow: auto;
    table{
        tbody{
            tr{
                td{
                    vertical-align: top;
                    position: relative;
                    &:first-child{
                        width: 180px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        z-index: 9;
                        background: #fff;
                        left: 0px;
                    }
                    &:nth-child(2){
                        width: 140px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        z-index: 9;
                        background: #fff;
                        left: 180px;
                    }
                    &:last-child{
                        width: 140px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: -webkit-sticky;
                        position: sticky;
                        z-index: 9;
                        background: #fff;
                        right: 0px;

                    }
                }
            }
        }
        thead{
            tr{
            th{
                background: #e3e7f1;
              &:nth-child(1){
                width: 180px;
                white-space: normal;
                padding-left: 15px;
                padding-right: 15px;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 10;
                left: 0;
                border-radius: 6px 0 0 0;
            }
              
              &:nth-child(2){
                width: 115px;
                white-space: normal;
                padding-left: 15px;
                padding-right: 15px;
                position: -webkit-sticky;
                position: sticky;
                z-index: 10;
                left: 180px;
              }
              &:nth-child(3){
                width: 150px;
              }
              &:nth-child(4){
                width: 200px;
              }
              &:nth-child(5){
                width: 210px;
              }
              &:nth-child(6){
                width: 160px;
              }
              &:nth-child(7){
                width: 240px;
              }
              &:nth-child(8){
                width: 180px;
              }
                &:nth-child(9){
                width: 150px;
              }
              &:nth-child(10){
                width: 150px;
              }
              &:nth-child(11){
                width: 160px;
              }
              &:nth-child(12){
                width: 150px;
              }
              &:nth-child(13){
                width: 130px;
              }
              &:last-child{
                border-radius: 0px 6px  0 0;
                width: 160px;
                white-space: normal;
                padding-left: 15px;
                padding-right: 15px;
                position: -webkit-sticky;
                position: sticky;
                z-index: 10;
                right: 0px;
              }
              
            }
        }
        }
    }
    }
}
.salary-report-outer{
    .right-btn{
        display: flex;
        align-items: center;
        .btn-line{
            margin-left: 10px;
            min-width: auto;
            padding: 0px 25px;
            display: flex;
            align-items: center;
            line-height: normal;
            .ic-add{
                font-size: 20px;
                margin-right: 5px;
            }
        }
        .fam-filter-toggle{
            background: $white;
        }
    }
}
.model-popup-outer{
    .modal{
        z-index: 11;
    }
    .modal-main {
        border-radius: 15px;
        padding: 40px;
    }
}
.import-salary-data-popup{
    .modal-main {
        width: 440px;
    }
     .modal-body {
      min-height: 140px;
      max-height: 465px;
      overflow-y: inherit;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0 40px;
     }
     .download-txt{
        margin: 15px 0;
     }
     .upload-txt{
         span {
        width: 60%;
        display: block;
        text-align: center;
        margin: 0 auto;
         }
    }
}
.add-salary-data-popup{
    .material {
    .form-input{
        padding: 12px 15px;
        margin-bottom: 15px;
    }
    }
    .modal-main {
        width: 600px;
    }
    .datepicker
     input{
        padding: 13px 15px;
        margin-bottom: 15px;
     }
}