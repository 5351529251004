.single-select {
    .react-select__placeholder {
        font-size: 11px;
    }

    .react-select__indicators {
        svg {
            display: none;
        }
    }

    .react-select__indicator-separator,
    .css-109onse-indicatorSeparator {
        display: none;
    }

    .react-select__control--menu-is-open,
    .react-select__control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;

        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }

    .react-select__indicators {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .react-select__single-value,
    .css-1v99tuv {
        font-size: 12px;
    }

    .react-select__menu-list,
    .css-11unzgr {
        font-size: 12px;
    }

    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;

        input {
            position: relative;
            top: 3px;
        }
    }

    .react-select__single-value {
        max-width: 100% !important;
    }

    .react-select__value-container--has-value {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -16px;
        }
    }

    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -16px;
        }
    }

    .react-multiselect-opt {
        .react-select__option {
            display: flex;
            align-items: flex-start;

            input {
                appearance: auto !important;
            }

            label {
                position: relative;
                top: -3px;
                margin-left: 5px;
            }
        }
    }
}

.report-filter {
    .single-select {
        .react-select__value-container--has-value {
            .react-select__placeholder {
                top: -19px;
            }
        }

        .react-select__control--menu-is-open,
        .react-select__control--is-focused {
            .react-select__placeholder {
                top: -19px;
            }
        }
    }
}